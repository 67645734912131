import React from "react";
import "./whats.css";

const Whats = () => {
  return (
    <a
      href="https://wa.me/+916001098923"
      target="_blank"
      rel="noreferrer"
      className="whats"
    ></a>
  );
};

export default Whats;
