import React from "react";
import "./terms.css";

const Freelance = () => {
  return (
    <div className="terms_container">
      <p>
        <h3>Exciting Freelance Opportunities</h3> <br />
        <br />
        <br />
        <strong>1. Frontend Developer (React, HTML, CSS)</strong>
        <br />
        Join us to craft captivating user interfaces and enhance the overall
        user experience. Proficiency in React, HTML, and CSS is essential.{" "}
        <br />
        <br />
        <strong> 2. Backend Developer (Node.js, Express.js)</strong>
        <br />
        Contribute to the backbone of our platform by utilizing your expertise
        in Node.js and Express.js. Join our dynamic team and shape the
        server-side architecture. <br /> <br />
        <strong>
          {" "}
          3. Backend engineer to improve security (expertise in jwt, other
          authentication tool, cookies etc){" "}
        </strong>
        <br />
        Elevate our security measures! We're seeking an expert in JWT,
        authentication tools, cookies, and more. Strengthen our platform's
        security for a robust user experience.
        <br />
        <br />
        <strong>
          4. Product manager (basic computer operating, ms excel){" "}
        </strong>
        <br />
        Dive into the heart of product management. If you excel in basic
        computer operations and have a knack for organization, join us to steer
        our products to success. <br />
        <br />
        <strong> 5. UI/UX developer</strong> <br />
        Shape the visual identity and user experience of Gamusagogamukh.in.
        We're looking for a creative mind to enhance the design and usability of
        our platform. <br />
        <br />
        <a
          href="https://forms.gle/ajr1kMomrAPmagk46"
          target="_blank"
          rel="noreferrer"
        >
          Click to Apply
        </a>
      </p>
    </div>
  );
};

export default Freelance;
