import React from "react";
import "./terms.css";
import { BiMailSend } from "react-icons/bi";
import { FiPhoneCall } from "react-icons/fi";
import { BsWhatsapp } from "react-icons/bs";
import { PiGlobeHemisphereWestThin } from "react-icons/pi";
const About = () => {
  return (
    <div className="terms_container">
      <p>
        <br />
        <br />
        About assamesedress.shop <br />
        <br /> "assamesedress.shop, rooted in the heart of Assam, India, is a
        flourishing business dedicated to offering a diverse range of Assamese
        Traditional Attire. Our online platform provides a convenient avenue to
        purchase these authentic garments, meticulously crafted from
        high-quality, locally sourced materials. While our brick-and-mortar
        store graces Assam, our commitment extends nationwide, with seamless
        product deliveries across India accompanied by minimal shipping charges.
        <br />
        Introducing assamesedress.shop, a unique e-commerce platform poised to
        revolutionize the market for authentic Assamese traditional attire. Our
        online marketplace is dedicated to showcasing the timeless beauty of
        Assamese traditional clothes. At assamesedress.shop, we go beyond
        offering a mere product; we present a cultural experience, allowing
        customers to immerse themselves in the rich heritage of Assam through
        our curated collection of traditional attire. The garments, meticulously
        crafted from premium materials like Muga, Paat, and Silk, sourced
        organically from rural Assam, exude both elegance and sustainability.
        With a significant gap in the online market for Assamese traditional
        attire, assamesedress.shop seizes a lucrative opportunity for
        investment. Our platform stands as a testament to the fusion of
        tradition and technology, making cultural richness accessible to a
        global audience.
        <br />
        <br />
        <br />
        <br />
        <br />
        {/* All rights reserved. For any kind of copyright issue, technical issue,
        deals etc, Contact the developer by clicking the follwing links:
        <br />
        <br />
        <PiGlobeHemisphereWestThin className="contact_icon" />
        <a href="https://lahon.in" target="_blank" rel="noreferrer">
          portfolio site
        </a>
        <br />
        <br /> <FiPhoneCall className="contact_icon" />
        <a href="tel:+916001098923">call now</a>
        <br />
        <br />
        <BiMailSend className="contact_icon" />{" "}
        <a href="mailto:lahoncs@gmail.com" target="_blank" rel="noreferrer">
          send email
        </a>
        <br />
        <br />
        <BsWhatsapp className="contact_icon" />
        <a href="https://wa.me/+916001098923" target="_blank" rel="noreferrer">
          Whatsapp
        </a>
        <br />
        <br />
        The whats app icon is attributed to &nbsp;
        <a href="https://www.freepik.com/free-vector/whatsapp-icon-design_3049282.htm#query=whatsapp%20logo%20png&position=0&from_view=keyword&track=ais">
          Freepik
        </a> */}
      </p>
      <br />

      <br />
    </div>
  );
};

export default About;
