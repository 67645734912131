import React from "react";
import logo from "../../../assets/gamusawhite.png";
import "./footer.css";
import { Link } from "react-router-dom";
import Whats from "../Whats";

const Footer = () => {
  return (
    <footer>
      <div className="footer-container">
        <div className="leftFooter">
          <h4>ASSAM DRESS</h4>
          <Link to="/products/pa">Paat</Link>
          <Link to="/products/muga">Muga</Link>
          <Link to="/products/ahom">Ahom</Link>
          <Link to="/products/mis">Mising</Link>

          <Link to="/products/men">Men</Link>
          <Link to="/products/wom">Women</Link>
        </div>

        <div className="midFooter">
          <h4>ASSAM STUFF</h4>
          <Link to="/cart">My Cart</Link>
          <Link to="/contact">Help and Support</Link>
          <Link to="/privacy">Privacy Policy</Link>
          <Link to="/terms">Terms & Conditions</Link>
          <Link to="/freelance">Freelance</Link>
          <a
            href="https://lahon.in/static/media/Partnership.b87b359f6fcdfab8f48b.pdf"
            target="_blank"
            rel="noreferrer"
          >
            Partnership
          </a>
        </div>

        <div className="rightFooter">
          <h4>ASSAM SOCIAL</h4>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.instagram.com/assamesedress.shop?utm_source=qr&igsh=eHdkdzI1NXdtYnh0"
          >
            Instagram
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.facebook.com/profile.php/?id=61556497913129"
          >
            Facebook
          </a>
          <a target="_blank" rel="noreferrer" href="/">
            Youtube
          </a>
          <Link to="/about">About us</Link>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://wa.me/+916001098923"
          >
            Custom Design
          </a>{" "}
          <Link to="/about">Copyrights</Link>
        </div>
      </div>
      <div className="logo-container-footer">
        <img src={logo} alt="ASSAMESE DRESS" />
      </div>
      <div className="copyright">
        <Whats />

        <small>&copy; assamesedress.shop 2024</small>
      </div>
    </footer>
  );
};

export default Footer;
